<template>
  <TabView @tab-change="generateKey">
    <TabPanel :header="$t('tabPanelSetup')">
      <div class="setup-tab-ct">
        <div class="label-input-group">
          <span class="new-ui-label">{{ $t('defaultLanguageLabel') }}</span>
          <language-selector
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>

        <div class="label-input-group">
          <span class="new-ui-label">
            {{ $t('additionalLanguagesLabel') }}
          </span>
          <multi-language-selector
            :key="config.defaultLang"
            :value="additionalLanguages"
            @input="setLanguages"
            :disabled="!isAvailable"
            :default-language-code="config.defaultLang"
          />
        </div>

        <div class="label-input-group">
          <span class="new-ui-label">
            {{ $t('emailMessageType') }}
          </span>
          <Dropdown
            id="ignore_subscription"
            class="form-dropdown"
            v-model="config.ignore_subscription"
            :options="ignoreSubscriptionOptions"
            option-label="label"
            option-value="value"
            :disabled="readOnly"
          />
        </div>

        <div class="label-input-group" v-if="consentPreferencesAvailable">
          <div class="label-switch-group">
            <span class="new-ui-label">
              {{ $t('consentPreferenceType') }}
            </span>
            <InputSwitch
              :disabled="readOnly"
              v-model="config.consent_preference.enabled"
            />
          </div>
          <select-consent-preference
            v-if="config.consent_preference.enabled"
            :value="config.consent_preference.preference"
            :channel="ConsentChannels.EMAIL"
            :preferences="consentPreferences"
            :read-only="readOnly"
            @input="updateConsentPreference"
          />
        </div>

        <national-exclusion-check-settings
          class="label-input-group"
          v-model="config.exclusion"
          :disabled="readOnly"
        />
      </div>
    </TabPanel>
    <TabPanel :header="$t('tabPanelContent')">
      <!-- Content Section -->
      <div
        style="display: flex; justify-content: space-between; gap: 10px"
        :key="panelKeyState"
      >
        <div class="col-md-5 tab-ct">
          <TabView @tab-change="setPreviewLanguage" :key="getKey">
            <TabPanel
              style="padding-bottom: 0"
              v-for="(lang, index) in sortedLanguages"
              :header="getLanguage(lang)"
              :tab-index="index"
              :key="lang"
            >
              <div class="content-tab-ct">
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('emailSubjectLine') }}
                  </span>
                  <personalised-input
                    v-model="config.messages[getCurrentLanguage].subject"
                    type="text"
                    :profile-attributes="profileAttributes"
                    :disabled="readOnly"
                    :emoji-enabled="false"
                    :event-properties-enabled="false"
                    :ai-enabled="true"
                    :email-content="content[getCurrentLanguage]"
                  />
                </div>

                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('emailFromAddress') }}
                  </span>
                  <Dropdown
                    class="form-dropdown"
                    :filter="true"
                    v-model="config.messages[getCurrentLanguage].fromAddress"
                    @input="updateFromAddress"
                    :options="fromAddresses"
                    option-label="address"
                    option-value="address"
                    :placeholder="displayFromPlaceholder"
                    :disabled="readOnly || fromAddresses.length === 0"
                  />
                </div>

                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('emailFromName') }}
                  </span>
                  <InputText
                    class="form-input"
                    type="text"
                    v-model="config.messages[getCurrentLanguage].fromName"
                    :placeholder="$t('emailFromNameInputPlaceholder')"
                    :disabled="readOnly || fromAddresses.length === 0"
                  />
                </div>

                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('emailReplyToAddress') }}
                  </span>
                  <InputText
                    class="form-input"
                    type="text"
                    v-model="config.messages[getCurrentLanguage].replyToAddress"
                    :placeholder="$t('emailReplyToAddressInputPlaceholder')"
                    :disabled="readOnly"
                  />
                </div>

                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('emailReplyName') }}
                  </span>
                  <InputText
                    class="form-input"
                    type="text"
                    v-model="config.messages[getCurrentLanguage].replyToName"
                    :placeholder="$t('emailReplyNameInputPlaceholder')"
                    :disabled="readOnly"
                  />
                </div>

                <div class="label-input-group" v-if="emailAttachmentsEnabled">
                  <span class="new-ui-label" v-if="!readOnly">
                    {{ $t('emailAttachPdf') }}
                  </span>

                  <div
                    v-if="!readOnly"
                    @dragover="onPdfDragOver"
                    @dragleave="onPdfDragLeave"
                    @drop="onPdfDragDrop"
                    class="dropzone"
                  >
                    <p>{{ $t('emailDragToUploadPdf') }}</p>
                    <label class="btn btn-file btn-default">
                      <icon glyph="cloud-upload" />
                      {{ $t('emailAttachPdfBrowse') }}
                      <input type="file" accept=".pdf" @change="importFile" />
                    </label>
                  </div>
                  <div
                    v-for="(attachment, index) in config.messages[getCurrentLanguage].attachments"
                    :key="index"
                  >
                    <div v-if="attachment">
                      <label>
                        {{ $t('emailAttachedFile') }} {{ attachment.fileName }}
                      </label>
                      <Button
                        v-if="!readOnly"
                        :label="$t('emailAttachedFileDelete')"
                        @click="clearFile(index)"
                        class="p-button-outlined"
                        style="height: 30px; line-height: 0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        <!-- Preview Section -->
        <div class="col-md-7" style="padding-top: 5px; padding-right: 0">
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon
                  style="margin-bottom: 2px"
                  glyph="chevron-down"
                  size="14px"
                />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.EMAIL"
              :content-id="config.messages[getCurrentLanguage].contentId"
              :preselected-template="{}"
              @applyMessageTemplate="importTemplate"
            />
          </div>
          <div class="preview">
            <iframe
              v-if="config.messages[getCurrentLanguage].contentId > 0"
              class="iframe-seamless"
              width="100%"
              height="100%"
              :srcdoc="content[getCurrentLanguage]"
              :key="key"
            />
            <div v-if="config.messages[getCurrentLanguage].contentId > 0" class="btn-section">
              <Button
                :disabled="readOnly"
                :label="$t('editBtn')"
                @click="prepareEdit()"
              />
              <Button
                :disabled="readOnly"
                :label="$t('deleteBtn')"
                @click="onDelete"
                class="p-button-outlined"
              />
            </div>
            <div v-else class="btn-section">
              <Button
                :disabled="readOnly"
                :label="$t('emailCreateWithBuilder')"
                @click="prepareBuilder()"
              />
              <Button :disabled="readOnly" @click="$refs.fileInput.click()">
                <span style="font-weight: 700">{{ $t('Upload HTML') }}</span>
                <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="text/html"
                  @change="importHtmlFile"
                />
              </Button>
            </div>
          </div>
          <div class="template-summary" v-tooltip:top="formatTemplateTooltip">
            {{ formatTemplateInfo }}
          </div>
        </div>
      </div>
    </TabPanel>
    <component
      v-if="showEditor && !readOnly"
      :is="editorComponent[getCurrentLanguage]"
      :workflow="workflow"
      :action="action"
      :message-content-id="config.messages[getCurrentLanguage].contentId"
      :language="getCurrentLanguage"
      @close="onClose"
      @save="onSave"
      :read-only="readOnly"
    />
  </TabView>
</template>
<script>
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import { MessageTypes } from './MessageTypesEnum'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import ISO6391 from 'iso-639-1'
import PersonalisedInput from '@/components/PersonalisedInput'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import SelectConsentPreference from '@/components/SelectConsentPreference'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings'
import Button from 'primevue/button'
import * as Toastr from 'toastr'
import XpApi from '@/libs/XpApi'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import ImportMessages from './Email/Dialog/ImportMessages.vue'
import VueDialog from '@/libs/VueDialog'
import moment from 'moment'
import BlankTemplate from './Email/Builder/BlankTemplate'
import Upload from './Email/Upload'
import Builder from './Email/Builder'
import Editor from './Email/Editor'
import alert from '@/libs/Alert'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi', 'contentApi'],

  components: {
    ApplyTemplateDialog,
    Button,
    NationalExclusionCheckSettings,
    SelectConsentPreference,
    InputSwitch,
    Dropdown,
    PersonalisedInput,
    InputText,
    TabPanel,
    TabView,
    LanguageSelector,
    MultiLanguageSelector,
    Upload,
    Builder,
    Editor,
  },

  data() {
    const config = Object.assign(
      {
        ignore_subscription: 'doNotIgnore',
        consent_preference: {
          enabled: false,
          preference: null
        },
        exclusion: {
          check: false,
          category: null
        },
        messages: {
          en: {
            subject: '',
            fromName: '',
            fromAddress: '',
            replyToName: '',
            replyToAddress: '',
            analyticsCode: '',
            attachments: [],
            contentId: 0
          }
        }
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      messagePreviewLanguage: '',
      panelKeyState: 1,
      config,
      MessageTypes,
      ConsentChannels,
      aiLoading: false,
      showSubjectAttributes: false,
      profileAttribute: '',
      loaded: false,
      ignoreSubscriptionOptions: [
        { value: 'doNotIgnore', label: this.$t('emailMessageTypeMarketing') },
        { value: 'ignore', label: this.$t('emailMessageTypeTransactional') }
      ],
      maxAttachments: 4,
      temporaryFileName: '',
      showEditor: false,
      content: {
        en: ''
      },
      editorComponent: {
        en: 'builder'
      },
      key: 0,
      setDialogVisible: false
    }
  },

  beforeDestroy() {
    if (this.isAvailable) {
      this.updateConfig()
    }
  },

  created() {
    this.$store.dispatch('fetchSenderDetails')
    this.$store.dispatch('fetchAttributes')
    this.$store.dispatch('fetchBuilderTemplates')
    this.loaded = true
  },

  mounted() {
    Object.entries(this.config.messages).forEach(([language, message]) => {
      if (message.contentId) {
        this.contentApi.load(message.contentId).then(({ content, meta }) => {
          this.content[language] = content
          this.editorComponent[language] = meta.editor || 'editor'
        })
      }
    })
  },

  computed: {
    watchedContentId() {
      return this.config.messages[this.getCurrentLanguage]?.contentId;
    },

    languages() {
      return this.config.languages
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    sortedLanguages() {
      return [this.config.defaultLang, ...this.additionalLanguages]
    },

    getCurrentLanguage() {
      return this.messagePreviewLanguage || this.config.defaultLang
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.emailEnabled
    },

    getKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      return this.config.defaultLang + this.config.languages.length
    },

    profileAttributes() {
      let attributes = this.$store.state.project.attributes

      if (attributes.length === 0) {
        return [{}]
      }

      return this.$store.state.project.attributes
    },

    fromAddresses() {
      return this.$store.state.project.senderDetails
    },

    displayFromPlaceholder() {
      if (!this.loaded) {
        return `${this.$t('emailFromAddressSelectLoadingPlaceholder')}`
      }

      if (this.fromAddresses.length === 0) {
        return this.$t('emailFromAddressSelectNoDetailsAvailable')
      }

      return this.$t('emailFromAddressSelectOption')
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    consentPreferencesAvailable() {
      return (
        this.config.ignore_subscription !== 'ignore' &&
        (this.workflow.features.consent_preferences ||
          this.$store.getters.isFeatureEnabled('consentPreferences'))
      )
    },

    emailAttachmentsEnabled() {
      return this.$store.getters.isFeatureEnabled('emailAttachmentsEnabled')
    },

    formatTemplateTooltip() {
      const whoApplied =
        this.config.templateInfo?.[this.getCurrentLanguage]?.whoApplied
      if (!whoApplied) {
        return ''
      }

      const lastApplied = moment(
        this.config.templateInfo?.[this.getCurrentLanguage]?.lastApplied
      ).format('DD/MMM/YYYY HH:mm')

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo() {
      const templateTitle =
        this.config.templateInfo?.[this.getCurrentLanguage]
          ?.templateTitle
      if (!templateTitle) {
        return ''
      }

      return this.$t('templateCreatedFrom') + templateTitle
    }
  },

  methods: {
    generateKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      // This will make sure we go to the first tab as that will always be the default language tab
      this.panelKeyState += this.panelKeyState
      this.messagePreviewLanguage = ''
    },

    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }

      this.config.defaultLang = lang
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    },

    setPreviewLanguage({ index }) {
      this.messagePreviewLanguage = this.sortedLanguages[index]
    },

    updateConfig() {
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    },

    updateConsentPreference(settings) {
      this.config.consent_preference.preference = settings.value
      this.config.consent_preference.preference_name = settings.name
    },

    onPdfDragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.add('dropzone-active')
    },

    onPdfDragLeave(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
    },

    onPdfDragDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
      this.processFileImport(e.dataTransfer.files[0])
    },

    importFile(e) {
      this.processFileImport(e.target.files[0])
      e.target.value = ''
    },

    processFileImport(file) {
      if (!file) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToHandle'))
        return
      }
      if (
        this.config.messages[this.getCurrentLanguage].attachments.length >= this.maxAttachments
      ) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToUpload'))
        return
      }

      if (file.type !== 'application/pdf') {
        this.displayErrorDialog(
          this.$t('emailFileImportErrorWrongFormat', { format: file.type })
        )
        return
      }
      this.uploadPdf(file)
      this.temporaryFileName = file.name
    },

    uploadPdf(file) {
      let projectId = this.$route.params.projectId
      const data = new FormData()
      data.append('attachment', file)
      XpApi.post('projects/' + projectId + '/campaigns/attachment', data)
        .then((response) => {
          this.updatePdfDetails(response.data.file)
        })
        .catch(({ response }) => {
          this.displayErrorDialog(response.data.message)
        })
    },

    updatePdfDetails(path) {
      const attachment = {
        fileName: this.temporaryFileName,
        pdf: path
      }

      this.config.messages[this.getCurrentLanguage].attachments.push(attachment)
    },

    clearFile(index) {
      this.config.messages[this.getCurrentLanguage].attachments.splice(index, 1)
      this.temporaryFileName = ''
    },

    displayErrorDialog(msg) {
      Toastr.error(msg)
    },

    prepareEdit() {
      document.body.style.pointerEvents = 'none'
      this.showEditor = 1
    },

    prepareBuilder() {
      document.body.style.pointerEvents = 'none'
      const template = {
        title: 'Blank',
        html: '',
        template: JSON.stringify(BlankTemplate)
      }

      this.editorComponent[this.getCurrentLanguage] = 'builder'
      this.importTemplate(template)
      this.showEditor = 1
    },

    importTemplate(template) {
      this.setDialogVisible = false
      if (!template.id && template.template) {
        this.applyTemplate('', template.template, 0, template.title)
        return
      }

      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: {
          fields: 'id,title',
          lang: this.getCurrentLanguage,
          expand: 'message'
        }
      }).then(({ data: template }) => {
        this.applyTemplate(
          template.message.email_html,
          template.message.email_template,
          template.id,
          template.title
        )
      })
    },

    applyTemplate(html, template, id, title) {
      let saveContent = template
        ? this.contentApi.saveBuilder(html, JSON.parse(template))
        : this.contentApi.saveEditor(html)

      saveContent.then(({ meta, contentId }) => {
        if (meta.messages.length) {
          VueDialog.show(ImportMessages, { messages: meta.messages })
        }

        this.config.messages[this.getCurrentLanguage].contentId = contentId

        if (contentId) {
          this.contentApi.load(contentId).then(({ content, meta }) => {
            this.content[this.getCurrentLanguage] = content
            this.editorComponent[this.getCurrentLanguage] = meta.editor || 'editor'
            this.key = this.key + 1
          })
        }

        this.config.templateInfo = this.config.templateInfo || {}
        this.config.templateInfo[this.getCurrentLanguage] = {
          templateId: id,
          templateTitle: title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        }

        this.updateConfig()
      })
    },

    onDelete() {
      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('templateDeleteAlertOk'),
        title: this.$t('templateDeleteAlertTitle'),
        message: this.$t('templateDeleteAlertMessage'),
        onOk: (resolve) => {
          this.doDelete()
          resolve()
        }
      })
    },

    doDelete() {
      this.config.messages[this.getCurrentLanguage].contentId = 0
      delete this.config.templateInfo[this.getCurrentLanguage]
      this.updateConfig()
    },

    importHtmlFile(e) {
      this.processHtmlImport(e.target.files[0])
      e.target.value = ''
    },

    processHtmlImport(file) {
      if (!file) {
        this.displayErrorDialog('Failed to handle file upload.')
        return
      } else if (file.type !== 'text/html') {
        this.displayErrorDialog(
          `Wrong File Format '${file.type}'. Please provide a HTML file.`
        )
        return
      } else if (file.size > 250000) {
        this.displayErrorDialog('Specified file is too large.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = (evt) => {
        if (evt.target.readyState !== FileReader.DONE) {
          return
        }

        const content = evt.target.result
        this.applyTemplate(content)
      }

      reader.readAsBinaryString(file.slice(0, file.size))
    },

    onClose() {
      this.showEditor = false
    },

    onSave(contentId) {
      this.config.messages[this.getCurrentLanguage].contentId = contentId

      this.updateConfig()
    },

    updateFromAddress(value) {
      let fromName = this.fromAddresses.find(
        (fromAddress) => fromAddress.address === value
      ).name

      this.config.messages[this.getCurrentLanguage]['fromAddress'] = value
      this.config.messages[this.getCurrentLanguage]['fromName'] = fromName

      if (
        this.config.messages[this.getCurrentLanguage]['replyToName'] === '' ||
        this.config.messages[this.getCurrentLanguage]['replyToName'] === this.config.messages[this.getCurrentLanguage]['fromName']
      ) {
        this.config.messages[this.getCurrentLanguage]['replyToName'] = fromName
      }

      if (
        this.config.messages[this.getCurrentLanguage]['replyToAddress'] === '' ||
        this.config.messages[this.getCurrentLanguage]['replyToAddress'] === this.config.messages[this.getCurrentLanguage]['fromAddress']
      ) {
        this.config.messages[this.getCurrentLanguage]['replyToAddress'] = value
      }
    },
  },

  watch: {
    watchedContentId(newVal) {
      if (newVal) {
        this.contentApi.load(newVal).then(({ content, meta }) => {
          this.content[this.getCurrentLanguage] = content
          this.key = this.key + 1
          this.editorComponent[this.getCurrentLanguage] = meta.editor || 'editor'
        })
      }
    },

    languages: {
      immediate: true,
      handler(languages) {
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              subject: '',
              fromName: '',
              fromAddress: '',
              replyToName: '',
              replyToAddress: '',
              analyticsCode: '',
              attachments: [],
              contentId: 0
            })
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
          }
        })

        this.updateConfig()
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.setup-tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%

.content-tab-ct
  display: flex
  flex-direction: column
  gap: 20px

.tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%
  flex-grow: 1

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.label-switch-group
  display: flex
  align-items: center
  width: 300px
  justify-content: space-between

::v-deep .label-switch-group
  display: flex
  align-items: center
  width: 300px
  justify-content: space-between
  gap: unset

.new-ui-label
  color: #4b515f
  font-weight: 600

.new-ui-label-width
  color: #4b515f
  font-weight: 600

.p-inputtext
  width: 100%

.p-button
  margin-left: 10px

.p-button:focus
  box-shadow: none

.dropzone
  text-align: center
  border: 2px dashed #ddd
  padding: 15px
  border-radius: 5px

.dropzone-active
  border: 2px dashed #888

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.btn-section
  position: absolute
  bottom: 15px
  max-width: fit-content
  margin-left: auto
  margin-right: auto

.preview
  background-color: #f8fafc
  border-radius: 4px
  min-height: 660px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative

.iframe-seamless
  position: absolute
  background-color: #ffffff
  width: calc(100% - 40px)
  height: 100%
  max-height: 565px
  border: 0
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
  left: 0
  top: 0
  margin: 20px 20px 0 20px
  box-sizing: border-box

::v-deep .select2-container--bootstrap.select2-container--disabled .select2-selection
  background-color: unset
  opacity: 0.6
  cursor: default

#overlay_menu
  top: 0
  left: 0
  right: 0

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right
</style>
